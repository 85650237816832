import { VideoPlayBox } from "@components/LandingPage/VideoPlayBox";
import { AppInstance } from "@shared/utils/app-instance";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { LandingPageContainer } from "@ui/Container";
import { FlexBox } from "@ui/FlexBox";
import { Image } from "@ui/Image";
import { SmileShape } from "@ui/SmileShape";
import { Text, TextH4 } from "@ui/Text";
import { spacing } from "@uxf/styles/units/spacing";
import { FC, memo } from "react";
import { useTheme } from "styled-components";

const LandingPageOurQuoteSectionComponent: FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <LandingPageContainer
            as="section"
            mb={[spacing(8), spacing(11), null, null, spacing(15)]}
            mt={[spacing(5), null, spacing(8), null, spacing(11)]}
        >
            <FlexBox alignItems="center" flexDirection={["column-reverse", null, "row"]}>
                {AppInstance.isSlovak ? (
                    <Image
                        src="/static/images/hp_image.jpg"
                        width={600}
                        height={314}
                        boxShadow={theme.shadow.dropdown}
                        mt={[spacing(5), null, 0]}
                        mr={[null, null, spacing(5)]}
                    />
                ) : (
                    <VideoPlayBox
                        boxShadow={theme.shadow.dropdown}
                        mt={[spacing(5), null, 0]}
                        mr={[null, null, spacing(5)]}
                        videoId="39Rg6gPjdgM"
                    />
                )}

                <Box>
                    <Text as="div" textAlign={["center", null, "left"]}>
                        <SmileShape id="landingQuoteSmile" variant="primary" width={58} />
                    </Text>
                    <TextH4 textFontStyle="italic" mt={spacing(3)} textFontWeight="medium">
                        <q>{t("component-landing-our-quote:creating-web")}</q>
                    </TextH4>
                </Box>
            </FlexBox>
        </LandingPageContainer>
    );
};

export const LandingPageOurQuoteSection = memo(LandingPageOurQuoteSectionComponent);

LandingPageOurQuoteSection.displayName = "LandingPageOurQuoteSection";
