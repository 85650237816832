import { DISABLED_OPACITY, FOCUS_VISIBLE_CLASSNAME } from "@styles/constants";
import { Box } from "@ui/Box";
import { FlexBox } from "@ui/FlexBox";
import { GhostButton, IGhostButtonProps } from "@ui/GhostButton";
import { NewClickable } from "@ui/NewClickable";
import { CssHelper } from "@utils/CssHelper";
import { Responsive } from "@utils/Responsive";
import { spacing } from "@uxf/styles/units/spacing";
import { mediaMin, withUnit } from "@uxf_base/utils/styling";
import transparentize from "polished/lib/color/transparentize";
import rem from "polished/lib/helpers/rem";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { RootProps, TabClickableProps, TabsContentProps } from "./types";

export const Root = styled(Box)<RootProps>(
    ({ scrollEnd, scrollStart, theme }) => css`
        border-bottom: ${withUnit(theme.border.default, "px")} ${theme.color.palette.inputBorder} solid;
        cursor: default;
        position: relative;
        width: 100%;

        &::after,
        &::before {
            backface-visibility: hidden;
            bottom: 0;
            content: "";
            display: block;
            pointer-events: none;
            position: absolute;
            top: 0;
            transition: opacity 100ms linear;
            width: ${rem(spacing(5))};
            z-index: 2;

            ${mediaMin(theme.breakpoint.md)} {
                width: ${rem(spacing(10))};
            }
        }

        &::after {
            background-image: linear-gradient(
                to left,
                ${transparentize(1, theme.color.palette.bgLight)},
                ${theme.color.palette.bgLight}
            );
            display: ${scrollStart ? "none" : "block"};
            left: 0;
        }

        &::before {
            background-image: linear-gradient(
                to right,
                ${transparentize(1, theme.color.palette.bgLight)},
                ${theme.color.palette.bgLight}
            );
            display: ${scrollEnd ? "none" : "block"};
            right: 0;
        }
    `,
);

export const ScrollContainer = styled("div")<HTMLAttributes<HTMLDivElement>>`
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    position: relative;
    white-space: nowrap;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const TabsContent = styled(FlexBox)<TabsContentProps>`
    display: inline-flex;
    flex-direction: row;
    width: auto;

    &&& > * {
        display: inline-table;
    }
`;

export const Button = styled(GhostButton)<IGhostButtonProps>`
    &&& {
        position: absolute;
        top: ${rem(2)};
        z-index: 3;
    }
`;

export const PrevButton = styled(Button)<IGhostButtonProps>`
    &&& {
        left: ${rem(4)};
    }
`;

export const NextButton = styled(Button)<IGhostButtonProps>`
    &&& {
        right: ${rem(4)};
    }
`;

export const TabTitle = styled("span")<HTMLAttributes<HTMLSpanElement>>`
    padding-top: ${spacing(1)};
`;

export const TabClickable = styled(NewClickable)<TabClickableProps>(
    ({
        $borderWidth,
        $colorVariant = "primary",
        $height = 32,
        $inheritSelectedColor = false,
        $isTabSelected,
        theme,
    }) => css`
        align-items: flex-start;
        color: ${$isTabSelected
            ? $inheritSelectedColor
                ? theme.color.palette[$colorVariant]
                : theme.color.palette.text
            : theme.color.palette.textMuted};
        display: flex;
        justify-content: center;
        position: relative;
        text-decoration: none;
        width: 100%;
        ${Responsive.facePaintedMin({
            height: CssHelper.value($height),
        })}

        &::before {
            border-bottom: ${withUnit($isTabSelected ? $borderWidth ?? theme.border.thick : theme.border.default, "px")}
                solid ${$isTabSelected ? theme.color.palette[$colorVariant] : "transparent"};
            bottom: 0;
            content: "";
            display: block;
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            width: 100%;
        }

        &::after {
            border-bottom: ${withUnit($borderWidth ?? theme.border.thick, "px")} solid
                ${theme.color.palette[$colorVariant]};
            bottom: 0;
            content: "";
            display: block;
            position: absolute;
            transform: scaleX(0);
            transition: ${CssHelper.transition("transform")};
            width: 100%;
        }

        &:active,
        &:hover {
            color: ${$inheritSelectedColor ? theme.color.palette[$colorVariant] : theme.color.palette.text};
            transition: ${CssHelper.transition("borderBottom")}, ${CssHelper.transition("color")};

            &::after {
                border-bottom-color: ${theme.color.palette[$colorVariant]};
                transform: scaleX(1);
            }
        }

        &:focus {
            outline-style: none;
        }

        &.${FOCUS_VISIBLE_CLASSNAME} {
            ${TabTitle} {
                box-shadow: ${theme.shadow.buttonFocus};
            }
        }

        &[aria-disabled="true"] {
            opacity: ${DISABLED_OPACITY};
            outline-style: none;
            pointer-events: none;
        }
    `,
);
