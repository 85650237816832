import { routeToUrl } from "@app-routes";
import { VideoPlayBox } from "@components/LandingPage/VideoPlayBox";
import { trackEvent } from "@services/analytics/trackEvent";
import { AppInstance } from "@shared/utils/app-instance";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { Button } from "@ui/Button";
import { LandingPageContainer } from "@ui/Container";
import { FlexBox } from "@ui/FlexBox";
import { Image } from "@ui/Image";
import { TextH2, TextLarge } from "@ui/Text";
import { Hide } from "@uxf/core/components/hide";
import { spacing } from "@uxf/styles/units/spacing";
import { FC, memo } from "react";
import { useTheme } from "styled-components";

const LandingPageOnlineVoteSectionComponent: FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Box
            as="section"
            backgroundColor="primaryBg"
            pt={spacing(5)}
            pb={spacing(5)}
            mb={[spacing(8), null, spacing(10)]}
        >
            <LandingPageContainer>
                <FlexBox alignItems="center" flexDirection={["column", null, null, "row"]}>
                    <Box mr={[null, null, null, spacing(8)]}>
                        <TextH2 as="h2" mb={[spacing(2), null, spacing(3)]} textVariant={["h4", null, "h2"]}>
                            {t("component-landing-online-vote:online-voting")}
                        </TextH2>
                        <TextLarge mb={spacing(4)}>{t("component-landing-online-vote:voting-at-meeting")}</TextLarge>
                        <FlexBox flexDirection={["column", "row"]} maxWidth={[null, 432]}>
                            <FlexBox flexDirection="column" boxWidth={[null, "50%"]}>
                                <Button
                                    analyticsCallback={trackEvent(
                                        "LandingPage",
                                        "Registrace",
                                        "Online hlasování sekce",
                                    )}
                                    href={routeToUrl("auth-zone/registration", {})}
                                    shadow
                                    size="huge"
                                    variant="primary"
                                >
                                    {t("component-landing-online-vote:try-for-free")}
                                </Button>
                            </FlexBox>

                            <FlexBox
                                flexDirection="column"
                                boxWidth={[null, "50%"]}
                                mt={[spacing(2), 0]}
                                ml={[null, spacing(3)]}
                            >
                                <Hide when={AppInstance.isSlovak}>
                                    <Button
                                        analyticsCallback={trackEvent(
                                            "LandingPage",
                                            "Online hlasování",
                                            "Online hlasování sekce",
                                        )}
                                        href={routeToUrl("landing-zone/new-online-vote")}
                                        variant="primary"
                                        size="huge"
                                        kind="outline"
                                    >
                                        {t("component-landing-online-vote:find-more")}
                                    </Button>
                                </Hide>
                            </FlexBox>
                        </FlexBox>
                    </Box>
                    <Box mt={[spacing(5), null, spacing(8), 0]}>
                        {AppInstance.isSlovak ? (
                            <Image
                                boxShadow={theme.shadow.dropdown}
                                src="/static/images/voting-example.png"
                                title={t("component-landing-online-vote:play-voting")}
                                aspectRatio={1071 / 895}
                            />
                        ) : (
                            <VideoPlayBox
                                aspectRatio={1069 / 609}
                                boxShadow={theme.shadow.dropdown}
                                imageSrc="/static/images/landingPage/video-vote-placeholder.png"
                                imageTitle={t("component-landing-online-vote:play-voting")}
                                boxWidth={[null, null, null, null, null, "90%"]}
                                videoId="cedoBszRWR0"
                            />
                        )}
                    </Box>
                </FlexBox>
            </LandingPageContainer>
        </Box>
    );
};

export const LandingPageOnlineVoteSection = memo(LandingPageOnlineVoteSectionComponent);

LandingPageOnlineVoteSection.displayName = "LandingPageOnlineVoteSection";
