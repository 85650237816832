import { routeToUrl } from "@app-routes";
import { AppInstance } from "@shared/utils/app-instance";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { LandingPageContainer } from "@ui/Container";
import { ForwardLink } from "@ui/ForwardLink";
import { Grid } from "@ui/Grid";
import { Image } from "@ui/Image";
import { TextH3, TextLarge } from "@ui/Text";
import { Hide } from "@uxf/core/components/hide";
import { spacing } from "@uxf/styles/units/spacing";
import { rem } from "polished";
import { CSSProperties, FC, memo } from "react";
import { useTheme } from "styled-components";
import { BenefitGridItem } from "./HomePage/BenefitGridItem";

const LandingPageSavingWorkSectionComponent: FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Box as="section" mb={[spacing(8), null, spacing(10)]}>
            <LandingPageContainer>
                <Grid
                    gridColumnCount={[1, 2, 3, 4]}
                    gridGap={[spacing(5), null, spacing(6), `${rem(spacing(6))} ${rem(spacing(8))}`]}
                    style={{ "--benefits-accent": theme.color.palette.primary } as CSSProperties}
                >
                    <Box
                        gridArea={[
                            "auto / auto / span 1 / span 1",
                            "auto / auto / span 1 / span 2",
                            "auto / auto / span 1 / span 3",
                            "auto / auto / span 1 / span 2",
                        ]}
                    >
                        <TextH3 as="h2" mb={[spacing(2), null, spacing(3)]} textVariant={["h4", null, "h3"]}>
                            {t("component-landing-saving-work:saving-job")}
                        </TextH3>
                        <TextLarge mb={spacing(1.5)}>{t("component-landing-saving-work:easy-admin")}</TextLarge>
                        <Hide when={AppInstance.isSlovak}>
                            <ForwardLink
                                href={routeToUrl("landing-zone/promo-work-saving")}
                                textColor="primary"
                                textFontWeight="semiBold"
                                textVariant="large"
                            >
                                {t("component-landing-saving-work:know-more")}
                            </ForwardLink>
                        </Hide>
                    </Box>
                    <Box
                        gridArea={[
                            null,
                            "auto / auto / span 1 / span 2",
                            "auto / auto / span 1 / span 3",
                            "auto / auto / span 2 / span 2",
                        ]}
                        pb={[spacing(2), null, spacing(4), 0]}
                        boxWidth={[null, null, null, null, null, "95%"]}
                    >
                        <Image
                            aspectRatio={1068 / 608}
                            boxShadow={theme.shadow.dropdown}
                            imgProps={{ alt: t("component-landing-saving-work:show-portal-web") }}
                            objectFit="contain"
                            role="presentation"
                            src="/static/images/landingPage/screenshot-overview-house.png"
                            title={t("component-landing-saving-work:show-portal-web")}
                        />
                    </Box>
                    <BenefitGridItem
                        icon="users"
                        title={t("component-landing-saving-work:owners-evidence")}
                        text={<>{t("component-landing-saving-work:owners-structure")}</>}
                    />
                    <BenefitGridItem
                        icon="file"
                        title={t("component-landing-saving-work:docs-templates")}
                        text={<>{t("component-landing-saving-work:find-templates")}</>}
                    />
                    <BenefitGridItem
                        icon="poll"
                        title={t("component-landing-saving-work:surveys")}
                        text={<>{t("component-landing-saving-work:let-vote-owners")}</>}
                    />
                    <BenefitGridItem
                        icon="settings"
                        title={t("component-landing-saving-work:opacity-settings")}
                        text={<>{t("component-landing-saving-work:use-users")}</>}
                    />
                    <BenefitGridItem
                        icon="comment"
                        title={t("component-landing-saving-work:chat-partners")}
                        text={<>{t("component-landing-saving-work:connect-anytime")}</>}
                    />
                    <Hide when={AppInstance.isSlovak}>
                        <BenefitGridItem
                            icon="hammer"
                            title={t("component-landing-saving-work:law-help")}
                            text={<>{t("component-landing-saving-work:do-your-decision")}</>}
                        />
                    </Hide>
                </Grid>
            </LandingPageContainer>
        </Box>
    );
};

export const LandingPageSavingWorkSection = memo(LandingPageSavingWorkSectionComponent);

LandingPageSavingWorkSection.displayName = "LandingPageSavingWorkSection";
