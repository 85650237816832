import { theme } from "@styles/theme";
import { Box, IBoxProps } from "@ui/Box";
import { Image } from "@ui/Image";
import { YouTubeVideo } from "@ui/YouTubeVideo";
import { FC, memo, useCallback, useState } from "react";

interface Props extends IBoxProps {
    aspectRatio?: number;
    imageSrc?: string;
    imageTitle?: string;
    videoHeight?: string;
    videoId: string;
    videoWidth?: string;
}

const VideoPlayBoxComponent: FC<Props> = (props) => {
    const {
        aspectRatio = 16 / 9,
        imageSrc = "/static/images/landingPage/video-home-placeholder.jpg",
        imageTitle = "Přehrát video - Sousedé.cz",
        videoHeight,
        videoId,
        videoWidth,
        ...restProps
    } = props;
    const [videoPlay, setVideoPlay] = useState(false);

    const showVideo = useCallback(() => setVideoPlay(true), []);

    return (
        <Box {...restProps}>
            {videoPlay ? (
                <YouTubeVideo videoHeight={videoHeight} videoId={videoId} videoWidth={videoWidth} />
            ) : (
                <Image
                    aspectRatio={aspectRatio}
                    boxShadow={theme.shadow.dropdown}
                    imgProps={{ alt: imageTitle, title: imageTitle }}
                    objectFit="cover"
                    onClick={showVideo}
                    src={imageSrc}
                    title={imageTitle}
                />
            )}
        </Box>
    );
};

export const VideoPlayBox = memo(VideoPlayBoxComponent);

VideoPlayBox.displayName = "VideoPlayBox";
