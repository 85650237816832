import { IconSizeEnum } from "@config/icons";
import { isBrowser } from "@uxf/core/utils/isBrowser";
import debounce from "lodash.debounce";
import {
    Children,
    cloneElement,
    FC,
    isValidElement,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import useSmoothScroll from "use-smooth-scroll";
import { NextButton, PrevButton, Root, ScrollContainer, TabsContent } from "./styles";
import { TabProps, TabsProps } from "./types";

// TODO add keys navigation in tabs (Skodak or...)

/*
const LEFT_RIGHT_ARROW_KEYS = (e: KeyboardEvent) => e.key === "ArrowRight" || e.key === "ArrowLeft";

const handleArrowKeysNavigation = useCallback(
    (e) => {
        const currentNode = e.target;
        const thisNode = activeTabTitleRef.current;
        if (currentNode && thisNode && currentNode === thisNode) {
            e.preventDefault();
            if (e.key === "ArrowRight" && activeTabIndex < tabsCount - 1) {
                setActiveTabIndex(activeTabIndex + 1);
            }
            if (e.key === "ArrowLeft" && activeTabIndex > 0) {
                setActiveTabIndex(activeTabIndex - 1);
            }
        }
    },
    [activeTabIndex, tabsCount],
);

useKey(activeTabTitleRef, LEFT_RIGHT_ARROW_KEYS, handleArrowKeysNavigation, "keydown");

useUpdateEffect(() => {
    const newNode = activeTabTitleRef.current;
    if (newNode) {
        newNode.focus();
    }
}, [handleArrowKeysNavigation]);
*/

export const Tabs: FC<TabsProps> = (props) => {
    const {
        $borderWidth,
        $colorVariant,
        $inheritSelectedColor = false,
        activeTabIndex,
        boxHeight = 32,
        children: childrenProp,
        onTabSelect,
        tabsIdPrefix,
        tabsContentProps,
        ...restProps
    } = props;

    const defaultScrollOffset = 300;

    const [scrollEnd, setScrollEnd] = useState(false);
    const [scrollStart, setScrollStart] = useState(true);

    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const scrollTo = useSmoothScroll("x", scrollContainerRef);

    const handleScrollBoundaries = useCallback(() => {
        const node = scrollContainerRef.current;
        if (node) {
            setScrollStart(node.scrollLeft === 0);
            setScrollEnd(node.scrollWidth - node.clientWidth - node.scrollLeft <= 0);
        }
    }, []);

    const handleContentWidth = useCallback(() => {
        const node = scrollContainerRef.current;
        const content = contentRef.current;

        if (node && content && content.scrollWidth <= node.clientWidth) {
            setScrollStart(true);
            setScrollEnd(true);
        } else {
            handleScrollBoundaries();
        }
    }, [handleScrollBoundaries]);

    useEffect(() => {
        const handler = () => handleScrollBoundaries();
        const node = scrollContainerRef.current;
        if (node) {
            node.addEventListener("scroll", handler);
            return () => {
                node.removeEventListener("scroll", handler);
            };
        }
    }, [handleScrollBoundaries]);

    useEffect(() => {
        if (isBrowser) {
            handleContentWidth();
            const debouncedHandleContentWidth = debounce(() => {
                handleContentWidth();
            }, 250);
            window.addEventListener("resize", debouncedHandleContentWidth);
            return () => {
                window.removeEventListener("resize", debouncedHandleContentWidth);
            };
        }
    }, [handleContentWidth]);

    const handlePrev = useCallback(() => {
        handleScrollBoundaries();
        const node = scrollContainerRef.current;
        if (node) {
            scrollTo(node.scrollLeft - defaultScrollOffset);
        }
    }, [defaultScrollOffset, handleScrollBoundaries, scrollTo]);

    const handleNext = useCallback(() => {
        handleScrollBoundaries();
        const node = scrollContainerRef.current;
        if (node) {
            scrollTo(node.scrollLeft + defaultScrollOffset);
        }
    }, [defaultScrollOffset, handleScrollBoundaries, scrollTo]);

    /*======= CHILDREN  ======*/

    let childIndex = 0;
    const children = Children.map(childrenProp, (child) => {
        if (!isValidElement(child)) {
            return null;
        }

        const childValue = child.props.value === undefined ? childIndex : child.props.value;
        const childColorVariant = child.props.$colorVariant === undefined ? $colorVariant : child.props.$colorVariant;

        const isTabSelected = childValue === activeTabIndex;

        childIndex += 1;

        return cloneElement(child as ReactElement<TabProps>, {
            $colorVariant: childColorVariant,
            $borderWidth,
            $inheritSelectedColor,
            boxHeight,
            isTabSelected,
            onTabSelect: !isTabSelected ? onTabSelect : undefined,
            tabsIdPrefix,
            value: childValue,
        });
    });

    /*======= / CHILDREN  ======*/

    return (
        <Root boxHeight={boxHeight} scrollEnd={scrollEnd} scrollStart={scrollStart} {...restProps}>
            <PrevButton hidden={scrollStart} iconSize={IconSizeEnum.medium} name="arrowLeft" onClick={handlePrev} />
            <ScrollContainer ref={scrollContainerRef}>
                <TabsContent ref={contentRef} role="tablist" {...tabsContentProps}>
                    {children}
                </TabsContent>
            </ScrollContainer>
            <NextButton hidden={scrollEnd} iconSize={IconSizeEnum.medium} name="arrowRight" onClick={handleNext} />
        </Root>
    );
};
