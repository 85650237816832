import { routeToUrl } from "@app-routes";
import { AppInstance } from "@shared/utils/app-instance";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { LandingPageContainer } from "@ui/Container";
import { ForwardLink } from "@ui/ForwardLink";
import { Grid } from "@ui/Grid";
import { Image } from "@ui/Image";
import { TextH3, TextLarge } from "@ui/Text";
import { Hide } from "@uxf/core/components/hide";
import { spacing } from "@uxf/styles/units/spacing";
import { rem } from "polished";
import { CSSProperties, FC, memo } from "react";
import { useTheme } from "styled-components";
import { BenefitGridItem } from "./HomePage/BenefitGridItem";

const LandingPageDutiesControlSectionComponent: FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Box as="section" mb={[spacing(8), null, spacing(10)]}>
            <LandingPageContainer>
                <Grid
                    gridColumnCount={[1, 2, 3, 4]}
                    gridGap={[spacing(5), null, spacing(6), `${rem(spacing(6))} ${rem(spacing(8))}`]}
                    style={{ "--benefits-accent": theme.color.palette.primary } as CSSProperties}
                >
                    <Box
                        gridArea={[
                            null,
                            "auto / auto / span 1 / span 2",
                            "auto / auto / span 1 / span 3",
                            "auto / auto / span 2 / span 2",
                        ]}
                        pb={[null, spacing(2), spacing(4), 0]}
                        boxWidth={[null, null, null, null, null, "95%"]}
                    >
                        <Image
                            aspectRatio={1068 / 608}
                            boxShadow={theme.shadow.dropdown}
                            imgProps={{ alt: "Ukázka portálu pro SVJ/BD Sousedé.cz" }}
                            objectFit="contain"
                            role="presentation"
                            src={
                                AppInstance.isCzech
                                    ? "/static/images/landingPage/screenshot-guardian-angel.png"
                                    : "/static/images/landingPage/screenshot-events-sk.png"
                            }
                            title={t("component-landing-duties-control:show-portal")}
                        />
                    </Box>
                    <Box
                        gridArea={[
                            "1 / 1 / span 1 / span 1",
                            "1 / 1 / span 1 / span 2",
                            "1 / 1 / span 1 / span 3",
                            "auto / auto / span 1 / span 2",
                        ]}
                    >
                        <TextH3 as="h2" mb={[spacing(2), null, spacing(3)]} textVariant={["h4", null, "h3"]}>
                            {t("component-landing-duties-control:control-duty")}
                        </TextH3>
                        <TextLarge mb={spacing(1.5)}>
                            {t("component-landing-duties-control:all-legal-duties")}
                        </TextLarge>
                        <Hide when={AppInstance.isSlovak}>
                            <ForwardLink
                                href={routeToUrl("landing-zone/promo-duties-control")}
                                textColor="primary"
                                textFontWeight="semiBold"
                                textVariant="large"
                            >
                                {t("component-landing-duties-control:know-more")}
                            </ForwardLink>
                        </Hide>
                    </Box>
                    <Hide when={AppInstance.isSlovak}>
                        <BenefitGridItem
                            icon="hammer"
                            title={t("component-landing-duties-control:insolvency-control")}
                            text={<>{t("component-landing-duties-control:worry-about-owners")}</>}
                        />
                    </Hide>
                    <BenefitGridItem
                        icon="notifications"
                        title={t("component-landing-duties-control:cadaster-update")}
                        text={<>{t("component-landing-duties-control:list-of-updates")}</>}
                    />
                    <BenefitGridItem
                        icon="calendar"
                        title={t("component-landing-duties-control:events")}
                        text={<>{t("component-landing-duties-control:list-of-close-events")}</>}
                    />
                    <BenefitGridItem
                        icon="lock"
                        title="GDPR"
                        text={<>{t("component-landing-duties-control:save-user-zone")}</>}
                    />
                    <BenefitGridItem
                        icon="mailInvite"
                        title={t("component-landing-duties-control:control-of-changes")}
                        text={<>{t("component-landing-duties-control:we-are-watching")}</>}
                    />
                    <Hide when={AppInstance.isSlovak}>
                        <BenefitGridItem
                            icon="file"
                            title={t("component-landing-duties-control:full-power-evidence")}
                            text={<>{t("component-landing-duties-control:all-powers-on-place")}</>}
                        />
                    </Hide>
                </Grid>
            </LandingPageContainer>
        </Box>
    );
};

export const LandingPageDutiesControlSection = memo(LandingPageDutiesControlSectionComponent);

LandingPageDutiesControlSection.displayName = "LandingPageDutiesControlSection";
