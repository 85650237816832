import { percent } from "@uxf/styles/units/percent";
import { withUnit } from "@uxf/styles/units/with-unit";
import clsx from "clsx";
import cover from "polished/lib/mixins/cover";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

export interface YouTubeVideoProps extends HTMLAttributes<HTMLDivElement> {
    $aspectRatio?: number;
    videoHeight?: string;
    videoId: string;
    videoWidth?: string;
}

export const YouTubeVideo = styled(({ className, videoHeight = "506.25", videoId, videoWidth = "900" }) => (
    <div className={clsx("video-responsive", className)}>
        <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            height={videoHeight}
            src={`https://www.youtube.com/embed/${videoId}`}
            title="Embedded youtube"
            width={videoWidth}
        />
    </div>
))<YouTubeVideoProps>(
    ({ $aspectRatio = 16 / 9, theme }) => css`
        border-radius: inherit;
        position: relative;

        &::before {
            content: "";
            display: block;
            padding-bottom: ${withUnit(percent(1 / $aspectRatio, 1), "%")};
            width: 100%;
        }

        & > iframe {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &::after {
            ${cover()};
            border: 1px solid ${theme.color.palette.border};
            border-radius: inherit;
            content: "";
            display: block;
            pointer-events: none;
        }
    `,
);
