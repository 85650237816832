import { Box, IBoxProps } from "@ui/Box";
import { spacing } from "@uxf/styles/units/spacing";
import { mediaMin, withUnit } from "@uxf_base/utils/styling";
import transparentize from "polished/lib/color/transparentize";
import rem from "polished/lib/helpers/rem";
import { HTMLAttributes, ImgHTMLAttributes } from "react";
import styled, { css } from "styled-components";

export const PatronsCaption = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        text-align: center;
        margin-bottom: ${rem(spacing(5))};

        ${mediaMin(theme.breakpoint.md)} {
            margin-bottom: ${rem(spacing(6))};
        }
    `,
);

export const Patron = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        padding: ${rem(theme.legacyGutter(2))};
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    `,
);

export const PatronDetail = styled("div")<HTMLAttributes<HTMLDivElement>>`
    align-items: center;
    display: flex;
`;

export const PatronImage = styled("img")<ImgHTMLAttributes<HTMLImageElement>>(
    ({ theme }) => css`
        border-radius: ${rem(theme.borderRadius.full)};
        height: 44px;
        width: 44px;
        object-fit: cover;
        margin-right: ${rem(theme.legacySpacing(2))};
    `,
);

export const PatronCredentials = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;

        & > :last-child {
            color: ${transparentize(0.5, theme.color.palette.text)};
        }
    `,
);

export const PatronBox = styled(Box)<IBoxProps>(
    ({ theme }) => css`
        border: ${withUnit(theme.border.default, "px") + " solid " + theme.color.palette.border};
    `,
);

PatronBox.defaultProps = {
    borderRadius: "default",
};
