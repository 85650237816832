import { RegisterItem } from "@components/LandingPage/HomePage/RegisterItem";
import { config } from "@config/config";
import { Trans } from "@translations/trans";
import { useTranslation } from "@translations/use-translation";
import { LandingPageContainer } from "@ui/Container";
import { Link } from "@ui/Link";
import { TextLarge } from "@ui/Text";
import { spacing } from "@uxf/styles/units/spacing";
import { FC, memo } from "react";

const ServiceRegisterTabComponent: FC = () => {
    const { t } = useTranslation();
    return (
        <LandingPageContainer mt={[spacing(8), null, null, spacing(12)]} mb={spacing(4)}>
            <RegisterItem
                subtitle={
                    <TextLarge>
                        {t("component-landing-home-service-register-tab:interested-about-zone")}
                        <br />
                        <Trans
                            i18nKey="component-landing-home-service-register-tab:contact-us"
                            components={{
                                link: (
                                    <Link
                                        href={`mailto:${config.TRANSLATION_BUSINESS_EMAIL}`}
                                        textColor="primary"
                                        textFontWeight="medium"
                                    />
                                ),
                            }}
                        />
                    </TextLarge>
                }
                title={t("component-landing-home-service-register-tab:members-will-love-you")}
            />
        </LandingPageContainer>
    );
};

export const ServiceRegisterTab = memo(ServiceRegisterTabComponent);

ServiceRegisterTab.displayName = "ServiceRegisterTab";
