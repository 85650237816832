import { useTranslation } from "@translations/use-translation";
import { LandingPageContainer } from "@ui/Container";
import { Grid } from "@ui/Grid";
import { SmileShape } from "@ui/SmileShape";
import { TextH2, TextLarge } from "@ui/Text";
import { FC, memo, useMemo } from "react";
import { useTheme } from "styled-components";
import { Patron, PatronBox, PatronCredentials, PatronDetail, PatronImage, PatronsCaption } from "./styles";
import { IPatronsProps } from "./types";

const PatronsComponent: FC<IPatronsProps> = (props) => {
    const { t } = useTranslation();
    const { data, customTitle = t("ui-patrons:we-share-your-experience"), variant = "primary" } = props;
    const theme = useTheme();

    const renderPatrons = useMemo(
        () =>
            data.map((patron, index) => {
                return (
                    <PatronBox key={`${patron}-patron-${index}`}>
                        <Patron>
                            <TextLarge mb={theme.legacySpacing(3)}>
                                <q>{patron.text}</q>
                            </TextLarge>

                            <PatronDetail>
                                {!!patron.img && <PatronImage src={patron.img} alt="Avatar" role="none" />}
                                <PatronCredentials>
                                    <TextLarge mr={theme.legacySpacing(3)} textFontWeight="medium">
                                        {patron.name ? patron.name : null}
                                    </TextLarge>
                                    <TextLarge>{patron.role}</TextLarge>
                                </PatronCredentials>
                            </PatronDetail>
                        </Patron>
                    </PatronBox>
                );
            }),
        [data, theme],
    );

    return (
        <LandingPageContainer mt={60} mb={40}>
            <PatronsCaption>
                <SmileShape id="patronsCaptionSmile" variant={variant} width={58} />
                <TextH2 mt={20} data-testid="patrons-sdilime-zkusenosti">
                    {customTitle}
                </TextH2>
            </PatronsCaption>

            <Grid gridColumnCount={[1, null, 2, 3]} gridGap={16}>
                {renderPatrons}
            </Grid>
        </LandingPageContainer>
    );
};

export const Patrons = memo(PatronsComponent);
