import { routeToUrl } from "@app-routes";
import { config } from "@config/config";
import { PRESENTATION_MEETING_URL } from "@config/landingPageData";
import { trackEvent } from "@services/analytics/trackEvent";
import { Trans } from "@translations/trans";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { Button } from "@ui/Button";
import { LandingPageContainer } from "@ui/Container";
import { FlexBox } from "@ui/FlexBox";
import { Link } from "@ui/Link";
import { SmileShape } from "@ui/SmileShape";
import { TextH1, TextH2, TextH5, TextLarge } from "@ui/Text";
import { spacing } from "@uxf/styles/units/spacing";
import { FC, memo } from "react";
import { HouseImage, HouseImageSvg, HouseImageWrapper, PROMO_HERO_SECTION_SVG_PATH } from "./styles";

const LandingPageHeroSectionComponent: FC = () => {
    const { t } = useTranslation();
    return (
        <Box
            as="section"
            backgroundColor="primaryBg"
            overFlow="hidden"
            pb={[spacing(5), null, null, spacing(10)]}
            pt={[spacing(5), null, spacing(8), spacing(10)]}
        >
            <LandingPageContainer boxPosition="relative">
                <HouseImageWrapper>
                    <HouseImageSvg>
                        <svg width={96} height={102}>
                            <defs>
                                <clipPath
                                    id={PROMO_HERO_SECTION_SVG_PATH}
                                    clipPathUnits="objectBoundingBox"
                                    transform="scale(0.01041666666 0.00978915662)"
                                    // scale with 1/96, 1/102
                                >
                                    <path d="M15 102c-8 0-15-6-15-14V39c0-4 2-8 6-11L39 3c5-4 12-4 18 0l33 25c4 3 6 7 6 11v49c0 8-7 14-15 14H15z" />
                                </clipPath>
                            </defs>
                        </svg>
                    </HouseImageSvg>
                    <HouseImage src="/static/images/landingPage/man-with-laptop.jpg" />
                    <SmileShape className="smile" id="heroSectionSmile" variant="primary" />
                </HouseImageWrapper>
                <Box maxWidth={680}>
                    <TextH1
                        mb={[spacing(2), null, spacing(3)]}
                        textAlign={["center", "left"]}
                        textFontWeight="semiBold"
                        textVariant={["h2", "h1", "hero"]}
                    >
                        {t("component-landing-page-hero-section:under-control")}
                    </TextH1>
                </Box>
                <Box maxWidth={[null, null, 400, 500, 550, 600]} mb={spacing(3)}>
                    <TextLarge
                        mb={[spacing(3), null, spacing(5)]}
                        textAlign={["center", "left"]}
                        textVariant={["large", "h4"]}
                    >
                        {t("component-landing-page-hero-section:connection-company")}
                    </TextLarge>
                    <FlexBox flexDirection={["column", "row"]} maxWidth={[null, 462]}>
                        <FlexBox flexDirection="column" boxWidth={[null, "50%"]}>
                            <Button
                                analyticsCallback={trackEvent("LandingPage", "Registrace", "Hero Section")}
                                href={routeToUrl("auth-zone/registration", {})}
                                shadow
                                size="giant"
                                variant="secondary"
                            >
                                {t("component-landing-page-hero-section:try-at-home")}
                            </Button>
                        </FlexBox>
                        <FlexBox
                            flexDirection="column"
                            boxWidth={[null, "50%"]}
                            mt={[spacing(2), 0]}
                            ml={[null, spacing(2)]}
                        >
                            <Button
                                analyticsCallback={trackEvent("LandingPage", "Ukázka", "Hero Section")}
                                href={PRESENTATION_MEETING_URL}
                                kind="outline"
                                size="giant"
                                target="_blank"
                                variant="secondary"
                            >
                                {t("component-landing-page-hero-section:ask-for-teaser")}
                            </Button>
                        </FlexBox>
                    </FlexBox>
                </Box>
                <TextLarge>
                    <Trans
                        components={{
                            link: (
                                <Link
                                    href={`mailto:${config.TRANSLATION_BUSINESS_EMAIL}`}
                                    textColor="secondary"
                                    textFontWeight="medium"
                                    underline
                                />
                            ),
                        }}
                        i18nKey="component-landing-page-hero-section:administrator-contact-us"
                    />
                </TextLarge>
                <FlexBox
                    maxWidth={680}
                    mt={[spacing(5), spacing(6)]}
                    flexDirection={["column", "row"]}
                    justifyContent={["center", "flex-start"]}
                >
                    <Box mb={[spacing(3), 0]}>
                        <TextH2 textAlign={["center", "left"]} textFontWeight="semiBold">
                            2 082
                        </TextH2>
                        <TextH5 textAlign={["center", "left"]}>
                            {t("component-landing-page-hero-section:flat-houses")}
                        </TextH5>
                    </Box>
                    <Box mb={[spacing(3), 0]}>
                        <TextH2 textAlign={["center", "left"]} textFontWeight="semiBold">
                            45 471
                        </TextH2>
                        <TextH5 textAlign={["center", "left"]}>{t("component-landing-page-hero-section:users")}</TextH5>
                    </Box>
                    <Box>
                        <TextH2 textAlign={["center", "left"]} textFontWeight="semiBold">
                            15 {t("component-landing-page-hero-section:years")}
                        </TextH2>
                        <TextH5 textAlign={["center", "left"]}>
                            {t("component-landing-page-hero-section:experience")}
                        </TextH5>
                    </Box>
                </FlexBox>
            </LandingPageContainer>
        </Box>
    );
};

export const LandingPageHeroSection = memo(LandingPageHeroSectionComponent);

LandingPageHeroSection.displayName = "LandingPageHeroSection";
