import { theme } from "@styles/theme";
import { Box } from "@ui/Box";
import { FlexBox } from "@ui/FlexBox";
import { Image } from "@ui/Image";
import { TextH3, TextLarge } from "@ui/Text";
import { YouTubeVideo } from "@ui/YouTubeVideo";
import { isNotNil } from "@uxf/core/utils/is-not-nil";
import { spacing } from "@uxf/styles/units/spacing";
import { CSSProperties, FC, ReactNode, useState } from "react";

interface FeatureItemProps {
    title: ReactNode;
    text?: ReactNode;
    imageSrc: string;
    imageTitle: string;
    videoId?: string;
    moreButton?: ReactNode;
    aspectRatio: number;
    isImageOnRightSide?: boolean;
}

export const FeatureItem: FC<FeatureItemProps> = (props) => {
    const [videoPlay, setVideoPlay] = useState(false);

    const showVideo = () => {
        if (props.videoId) {
            setVideoPlay(true);
        }
    };

    const imageBoxMargins = [null, null, spacing(8), spacing(12), spacing(16)];

    return (
        <FlexBox
            justifyContent="center"
            alignItems="center"
            flexDirection={["column-reverse", null, props.isImageOnRightSide ? "row-reverse" : "row"]}
            mt={[spacing(4), null, null, spacing(8), spacing(10), spacing(15)]}
            style={{ "--benefits-accent": theme.color.palette.primary } as CSSProperties}
        >
            <Box
                mr={props.isImageOnRightSide ? null : imageBoxMargins}
                ml={props.isImageOnRightSide ? imageBoxMargins : null}
                mt={[spacing(3), null, 0]}
            >
                {isNotNil(props.videoId) && videoPlay ? (
                    <YouTubeVideo
                        /*opts={{
                            height: "334",
                            width: "584",
                            playerVars: {
                                autoplay: true,
                                enablejsapi: 1,
                                list: "UC63PEUXSpoGf4Z-vy45ieZw",
                                listType: "user_uploads",
                                modestbranding: 1,
                                origin: "https://www.sousede.cz",
                                rel: 0,
                            },
                        }}*/
                        videoHeight="334"
                        videoId={props.videoId}
                        videoWidth="584"
                    />
                ) : (
                    <Image
                        aspectRatio={props.aspectRatio}
                        boxShadow={theme.shadow.dropdown}
                        imgProps={{ alt: props.imageTitle }}
                        objectFit="contain"
                        onClick={showVideo}
                        src={props.imageSrc}
                        title={props.imageTitle}
                    />
                )}
            </Box>
            <Box alignSelf="center">
                <TextH3 as="h2" mb={[spacing(2), null, spacing(3)]} textVariant={["h4", null, "h3"]}>
                    {props.title}
                </TextH3>
                <TextLarge mb={props.moreButton ? spacing(2) : undefined}>{props.text}</TextLarge>
                {props.moreButton}
            </Box>
        </FlexBox>
    );
};
