import { Box } from "@ui/Box";
import { FC } from "react";
import { TabPanelProps } from "./types";

export const TabPanel: FC<TabPanelProps> = (props) => {
    const { children, activeIndex, tabsIdPrefix, value, ...restProps } = props;

    const isExpanded = activeIndex === value;

    return (
        // eslint-disable-next-line jsx-a11y/role-supports-aria-props
        <Box
            aria-expanded={isExpanded}
            aria-labelledby={`${tabsIdPrefix}-tab-${value}`}
            hidden={!isExpanded}
            id={`tabpanel-${value}`}
            permanentlyHidden={!isExpanded}
            role="tabpanel"
            tabIndex={!isExpanded ? -1 : undefined}
            {...restProps}
        >
            {children}
        </Box>
    );
};
