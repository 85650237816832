import { routeToUrl } from "@app-routes";
import { AppInstance } from "@shared/utils/app-instance";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { LandingPageContainer } from "@ui/Container";
import { ForwardLink } from "@ui/ForwardLink";
import { Grid } from "@ui/Grid";
import { Image } from "@ui/Image";
import { TextH3, TextLarge } from "@ui/Text";
import { Hide } from "@uxf/core/components/hide";
import { spacing } from "@uxf/styles/units/spacing";
import { rem } from "polished";
import { CSSProperties, FC, memo } from "react";
import { useTheme } from "styled-components";
import { BenefitGridItem } from "./HomePage/BenefitGridItem";

const LandingPageOverviewSectionComponent: FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Box as="section" mb={[spacing(8), null, spacing(10)]}>
            <LandingPageContainer>
                <Grid
                    gridColumnCount={[1, 2, 3, 4]}
                    gridGap={[spacing(5), null, spacing(6), `${rem(spacing(6))} ${rem(spacing(8))}`]}
                    style={{ "--benefits-accent": theme.color.palette.primary } as CSSProperties}
                >
                    <Box
                        gridArea={[
                            null,
                            "auto / auto / span 1 / span 2",
                            "auto / auto / span 1 / span 3",
                            "auto / auto / span 2 / span 2",
                        ]}
                        pb={[null, spacing(2), spacing(4), 0]}
                        boxWidth={[null, null, null, null, null, "95%"]}
                    >
                        <Image
                            aspectRatio={AppInstance.isCzech ? 1068 / 608 : 1064 / 485}
                            boxShadow={theme.shadow.dropdown}
                            imgProps={{ alt: t("component-landing-page-overview:portal-shown") }}
                            objectFit="contain"
                            role="presentation"
                            src={
                                AppInstance.isCzech
                                    ? "/static/images/landingPage/screenshot-overview-house.png"
                                    : "/static/images/landingPage/screenshot-contacts-sk.png"
                            }
                            title={t("component-landing-page-overview:portal-shown")}
                        />
                    </Box>
                    <Box
                        gridArea={[
                            "1 / 1 / span 1 / span 1",
                            "1 / 1 / span 1 / span 2",
                            "1 / 1 / span 1 / span 3",
                            "auto / auto / span 1 / span 2",
                        ]}
                    >
                        <TextH3 as="h2" mb={[spacing(2), null, spacing(3)]} textVariant={["h4", null, "h3"]}>
                            {t("component-landing-page-overview:everything-view")}
                        </TextH3>
                        <TextLarge mb={spacing(1.5)}>{t("component-landing-page-overview:have-all-tools")}</TextLarge>

                        <Hide when={AppInstance.isSlovak}>
                            <ForwardLink
                                href={routeToUrl("landing-zone/promo-overview")}
                                textColor="primary"
                                textFontWeight="semiBold"
                                textVariant="large"
                            >
                                {t("component-landing-page-overview:discovered-more")}
                            </ForwardLink>
                        </Hide>
                    </Box>
                    <BenefitGridItem
                        icon="paperPin"
                        title={t("component-landing-page-overview:home-table")}
                        text={<>{t("component-landing-page-overview:communicate-transparently")}</>}
                    />
                    <BenefitGridItem
                        icon="file"
                        title={t("component-landing-page-overview:docs-and-pics")}
                        text={<>{t("component-landing-page-overview:able-docs")}</>}
                    />
                    <BenefitGridItem
                        icon="contacts"
                        title={t("component-landing-page-overview:contact-address")}
                        text={<>{t("component-landing-page-overview:all-important-contacts")}</>}
                    />
                    <BenefitGridItem
                        icon="megaphone"
                        title={t("component-landing-page-overview:fail-report")}
                        text={<>{t("component-landing-page-overview:portal-connection")}</>}
                    />
                    <BenefitGridItem
                        icon="houseAlt"
                        title={t("component-landing-page-overview:house-information")}
                        text={<>{t("component-landing-page-overview:hand-house")}</>}
                    />
                    <BenefitGridItem
                        icon="mobile"
                        title={t("component-landing-page-overview:mobil-app")}
                        text={<>{t("component-landing-page-overview:modern-app")}</>}
                    />
                </Grid>
            </LandingPageContainer>
        </Box>
    );
};

export const LandingPageOverviewSection = memo(LandingPageOverviewSectionComponent);

LandingPageOverviewSection.displayName = "LandingPageOverviewSection";
