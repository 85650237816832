import { routeToUrl } from "@app-routes";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { Button } from "@ui/Button";
import { LandingPageContainer } from "@ui/Container";
import { FlexBox } from "@ui/FlexBox";
import { Image } from "@ui/Image";
import { Link } from "@ui/Link";
import { SmileShape } from "@ui/SmileShape";
import { Text, TextH2, TextLarge } from "@ui/Text";
import { spacing } from "@uxf/styles/units/spacing";
import { FC, memo } from "react";

const LandingPageShareExperienceSectionComponent: FC = () => {
    const { t } = useTranslation();
    return (
        <Box
            as="section"
            backgroundColor="primaryBg"
            pb={[spacing(5), null, null, spacing(13)]}
            pt={[spacing(5), null, null, spacing(7)]}
        >
            <LandingPageContainer>
                <Text as="div" textAlign="center">
                    <SmileShape id="landingQuoteSmile" variant="primary" width={58} />
                    <TextH2
                        as="h2"
                        mb={[spacing(5), null, spacing(8)]}
                        mt={spacing(3.5)}
                        textVariant={["h3", "h2", null, "h1"]}
                    >
                        {t("component-landing-share-experience:sharing-skills")}
                    </TextH2>
                </Text>
                <FlexBox alignItems="center" flexDirection={["column", null, null, "row"]}>
                    <FlexBox
                        alignItems="center"
                        flexDirection={["column", "row"]}
                        mb={[spacing(6), null, null, 0]}
                        mr={[null, null, null, spacing(3)]}
                    >
                        <Box mb={[spacing(2), 0]} mr={[null, spacing(3)]}>
                            <Image
                                aspectRatio={282 / 162}
                                height={162}
                                imgProps={{
                                    alt: t("component-landing-share-experience:chairman-michael"),
                                    title: t("component-landing-share-experience:chairman-michael"),
                                }}
                                objectFit="cover"
                                role="presentation"
                                src="/static/images/landingPage/article-polach.jpg"
                                width={282}
                            />
                        </Box>
                        <Box>
                            <Link href={routeToUrl("magazine-zone/home")} textColor="primary" textFontWeight="medium">
                                {t("component-landing-share-experience:magazine")}
                            </Link>
                            <TextLarge mb={spacing(3)} mt={spacing(2)} textFontWeight="medium">
                                {t("component-landing-share-experience:web-give-info")}
                            </TextLarge>
                            <Button
                                href="https://www.sousede.cz/magazin/clanek/sousedecz-prinasi-mnohem-jednodussi-pristup-k-informacim-rika-predseda-michal-polach/1096"
                                kind="outline"
                                pl={spacing(2)}
                                pr={spacing(2)}
                            >
                                {t("component-landing-share-experience:show-article")}
                            </Button>
                        </Box>
                    </FlexBox>
                    <FlexBox alignItems="center" flexDirection={["column", "row"]}>
                        <Box mb={[spacing(2), 0]} mr={[null, spacing(3)]}>
                            <Image
                                src="/static/images/landingPage/article-horacek.jpg"
                                role="presentation"
                                imgProps={{
                                    alt: t("component-landing-share-experience:chairman-spring"),
                                    title: t("component-landing-share-experience:chairman-spring"),
                                }}
                                objectFit="cover"
                                aspectRatio={282 / 162}
                                width={282}
                                height={162}
                            />
                        </Box>
                        <Box>
                            <Link href={routeToUrl("magazine-zone/home")} textColor="primary" textFontWeight="medium">
                                {t("component-landing-share-experience:magazine")}
                            </Link>
                            <TextLarge mb={spacing(3)} mt={spacing(2)} textFontWeight="medium">
                                {t("component-landing-share-experience:need-to-go")}
                            </TextLarge>
                            <Button
                                href="https://www.sousede.cz/magazin/clanek/je-potreba-jit-s-dobou-rika-jaroslav-horacek/1116"
                                kind="outline"
                                pl={spacing(2)}
                                pr={spacing(2)}
                            >
                                {t("component-landing-share-experience:show-article")}
                            </Button>
                        </Box>
                    </FlexBox>
                </FlexBox>
            </LandingPageContainer>
        </Box>
    );
};

export const LandingPageShareExperienceSection = memo(LandingPageShareExperienceSectionComponent);

LandingPageShareExperienceSection.displayName = "LandingPageShareExperienceSection";
