import { theme } from "@styles/theme";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { Image } from "@ui/Image";
import { TextH3, TextLarge } from "@ui/Text";
import { spacing } from "@uxf/styles/units/spacing";
import { FC, ReactNode } from "react";

interface IProps {
    aspectRatio?: number;
    imageSrc: string;
    text: ReactNode;
    title: ReactNode;
}

export const FeatureGridItem: FC<IProps> = (props) => {
    const { aspectRatio, imageSrc, text, title } = props;
    const { t } = useTranslation();
    return (
        <Box>
            <Image
                boxShadow={theme.shadow.dropdown}
                src={imageSrc}
                role="presentation"
                imgProps={{ alt: "Ukázka portálu pro SVJ/BD Sousedé.cz" }}
                title={t("component-landing-home-feature-grid-item:presentation-of-portal")}
                objectFit="contain"
                aspectRatio={aspectRatio ?? 1068 / 608}
            />
            <TextH3
                as="h2"
                mb={[spacing(2), null, spacing(3)]}
                mt={[spacing(4), null, null, spacing(6)]}
                textVariant={["h4", null, "h3"]}
            >
                {title}
            </TextH3>
            <TextLarge>{text}</TextLarge>
        </Box>
    );
};
