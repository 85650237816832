import { IconsType } from "@config/icons";
import { Box } from "@ui/Box";
import { Icon } from "@ui/Icon";
import { TextH4, TextMedium } from "@ui/Text";
import { FC, ReactNode } from "react";

interface Props {
    icon: IconsType;
    inProgress?: boolean;
    largeTitle?: boolean;
    text: ReactNode;
    title: ReactNode;
}

export const BenefitGridItem: FC<Props> = (props) => {
    const { icon, inProgress, largeTitle, text, title } = props;

    return (
        <Box>
            <Icon
                name={icon}
                iconWidth={32}
                iconHeight={32}
                size={24}
                color={inProgress ? "textMuted" : "var(--benefits-accent)"}
                mb={16}
            />
            <TextH4 as="h3" textColor={inProgress ? "textMuted" : "text"} mb={8} textVariant={largeTitle ? "h4" : "h5"}>
                {title}
            </TextH4>
            <TextMedium textColor={inProgress ? "textMuted" : "text"}>{text}</TextMedium>
        </Box>
    );
};
