import { routeToUrl } from "@app-routes";
import { config } from "@config/config";
import { PRESENTATION_MEETING_URL } from "@config/landingPageData";
import { trackEvent } from "@services/analytics/trackEvent";
import { Trans } from "@translations/trans";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { Button } from "@ui/Button";
import { LandingPageContainer } from "@ui/Container";
import { FlexBox } from "@ui/FlexBox";
import { Link } from "@ui/Link";
import { TextH3, TextLarge } from "@ui/Text";
import { spacing } from "@uxf/styles/units/spacing";
import { FC, memo } from "react";

const LandingPageRegisterSectionComponent: FC = () => {
    const { t } = useTranslation();
    return (
        <Box
            as="section"
            backgroundColor="secondaryBg"
            pt={[spacing(5), null, null, spacing(8)]}
            pb={[spacing(5), null, null, spacing(8)]}
            mb={[spacing(8), null, spacing(10)]}
        >
            <LandingPageContainer>
                <FlexBox alignItems="center" flexDirection={["column", null, null, "row"]}>
                    <Box mb={[spacing(5), null, null, 0]} mr={[null, null, null, spacing(8)]}>
                        <Box boxWidth="auto" maxWidth={[null, null, 500]}>
                            <TextH3 textVariant={["h3", null, "h2"]}>
                                {t("component-landing-page-register-section:saving-time")}
                            </TextH3>
                        </Box>
                    </Box>
                    <Box>
                        <FlexBox flexDirection={["column", "row"]} maxWidth={[null, 584]} mb={spacing(3)}>
                            <FlexBox flexDirection="column" boxWidth={[null, "50%"]}>
                                <Button
                                    analyticsCallback={trackEvent(
                                        "LandingPage",
                                        "Registrace",
                                        "Ušetříte čas a peníze sekce",
                                    )}
                                    href={routeToUrl("auth-zone/registration", {})}
                                    shadow
                                    size="huge"
                                    variant="secondary"
                                >
                                    {t("component-landing-page-register-section:try-for-free")}
                                </Button>
                            </FlexBox>
                            <FlexBox
                                flexDirection="column"
                                boxWidth={[null, "50%"]}
                                mt={[spacing(2), 0]}
                                ml={[null, spacing(3)]}
                            >
                                <Button
                                    analyticsCallback={trackEvent(
                                        "LandingPage",
                                        "Ukázka",
                                        "Ušetříte čas a peníze sekce",
                                    )}
                                    href={PRESENTATION_MEETING_URL}
                                    kind="outline"
                                    size="huge"
                                    target="_blank"
                                    variant="secondary"
                                >
                                    {t("component-landing-page-register-section:ask-for-show")}
                                </Button>
                            </FlexBox>
                        </FlexBox>
                        <TextLarge>
                            <Trans
                                i18nKey="component-landing-page-register-section:is-it-admin"
                                components={{
                                    link: (
                                        <Link
                                            href={`mailto:${config.TRANSLATION_BUSINESS_EMAIL}`}
                                            textColor="secondary"
                                            textFontWeight="medium"
                                            underline
                                        />
                                    ),
                                }}
                            />
                        </TextLarge>
                    </Box>
                </FlexBox>
            </LandingPageContainer>
        </Box>
    );
};

export const LandingPageRegisterSection = memo(LandingPageRegisterSectionComponent);

LandingPageRegisterSection.displayName = "LandingPageRegisterSection";
