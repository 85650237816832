import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { FlexBox } from "@ui/FlexBox";
import { Image } from "@ui/Image";
import { Text, TextH3 } from "@ui/Text";
import { spacing } from "@uxf/styles/units/spacing";
import { FC, ReactNode } from "react";

interface IProps {
    title: ReactNode;
    text?: ReactNode;
    subtitle?: ReactNode;

    inProgress?: boolean;
}

export const RegisterItem: FC<IProps> = (props) => {
    const { inProgress, title, subtitle } = props;
    const { t } = useTranslation();
    return (
        <FlexBox justifyContent="center" alignItems="center" flexDirection={["column-reverse", null, "row"]}>
            <Box mt={[spacing(5), null, 0]} mr={[null, null, spacing(4), spacing(8)]}>
                <Text as="div" textAlign={["center", null, "left"]}>
                    <TextH3
                        textVariant={["h3", "h2", null, null, "h1"]}
                        textColor={inProgress ? "textMuted" : "text"}
                        mb={spacing(4)}
                    >
                        {title}
                    </TextH3>
                    {subtitle}
                </Text>
            </Box>
            <Box>
                <Image
                    className="illustration"
                    aspectRatio={515 / 345}
                    src="/static/images/mockups.png"
                    title={t("component-landing-home-register-item:digital-administration")}
                    imgProps={{ alt: "Digitální správa SVJ a BD na všech zařízeních" }}
                />
            </Box>
        </FlexBox>
    );
};
