import { config } from "@config/config";
import { AppInstance } from "@shared/utils/app-instance";
import { TranslationQuery } from "next-translate";
// eslint-disable-next-line no-restricted-imports
import BaseTrans from "next-translate/Trans";
import { ReactElement } from "react";
import { TranslationsKeys } from "../../next-translate";
// eslint-disable-next-line no-restricted-imports
import useTranslation from "next-translate/useTranslation";

interface TransProps {
    i18nKey: TranslationsKeys;
    components: Record<string, ReactElement>;
    values?: TranslationQuery;
}

export function Trans(props: TransProps) {
    const { t } = useTranslation();

    const queryWithGeneral = {
        ...(props.values ?? {}),
        general_application_name: config.TRANSLATION_APPLICATION_NAME,
        general_domain: config.TRANSLATION_DOMAIN,
        general_business_email: config.TRANSLATION_BUSINESS_EMAIL,
        general_caretaker_email: config.TRANSLATION_CARETAKER_EMAIL,
    };

    if (AppInstance.isSlovak) {
        const skKey = `${props.i18nKey}--sk`;
        const skTranslatedText = t(skKey as any, queryWithGeneral);
        if (skTranslatedText !== skKey) {
            return <BaseTrans i18nKey={skKey} components={props.components} values={queryWithGeneral} />;
        }
    }

    return <BaseTrans i18nKey={props.i18nKey} components={props.components} values={queryWithGeneral} />;
}
