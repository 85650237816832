import { mediaMin } from "@uxf_base/utils/styling";
import { rem } from "polished";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

interface HouseImageProps extends HTMLAttributes<HTMLDivElement> {
    src: string;
}

export const PROMO_HERO_SECTION_SVG_PATH = "landing-page-hero-section-svg-path";

export const HouseImage = styled("div")<HouseImageProps>(
    ({ src }) => css`
        background: #f6f6f2 url(${src}) center / cover;
        clip-path: url(#${PROMO_HERO_SECTION_SVG_PATH});
        display: block;
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
    `,
);

export const HouseImageSvg = styled("div")<HTMLAttributes<HTMLDivElement>>`
    display: block;
    position: absolute;
    height: 0;
    width: 0;
`;

export const HouseImageWrapper = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        bottom: -20px;
        display: block;
        height: 287px;
        left: 50%;
        margin-left: -${rem(135)};
        opacity: 20%;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        width: 270px;

        ${mediaMin(theme.breakpoint.sm)} {
            height: 372px;
            left: unset;
            margin-left: initial;
            opacity: 15%;
            right: -100px;
            width: 350px;
        }

        ${mediaMin(theme.breakpoint.md)} {
            height: 287px;
            opacity: 90%;
            overflow: visible;
            right: -15px;
            top: 70px;
            width: 270px;
        }

        ${mediaMin(theme.breakpoint.lg)} {
            height: 414px;
            opacity: 100%;
            top: 0;
            width: 390px;
        }

        ${mediaMin(theme.breakpoint.xl)} {
            height: 479px;
            right: -40px;
            top: -20px;
            width: 450px;
        }

        ${mediaMin(theme.breakpoint.xxl)} {
            height: 581px;
            top: -50px;
            width: 546px;
        }

        .smile {
            color: ${theme.color.palette.primary};
            left: 12%;
            position: absolute;
            top: 50%;
            width: 131%;
            z-index: 2;

            path {
                stroke-width: 3px;
            }

            ${mediaMin(theme.breakpoint.xl)} {
                path {
                    stroke-width: 4px;
                }
            }

            ${mediaMin(theme.breakpoint.xxl)} {
                path {
                    stroke-width: 5px;
                }
            }
        }
    `,
);
