import { ServiceBenefitTab } from "@components/LandingPage/HomePage/ServiceBenefitTab";
import { ServiceFeatureTab } from "@components/LandingPage/HomePage/ServiceFeatureTab";
import { ServiceRegisterTab } from "@components/LandingPage/HomePage/ServiceRegisterTab";
import { LandingPageDutiesControlSection } from "@components/LandingPage/LandingPageDutiesControlSection";
import { LandingPageHeroSection } from "@components/LandingPage/LandingPageHeroSection/LandingPageHeroSection";
import { LandingPageMockUpsSection } from "@components/LandingPage/LandingPageMockUpsSection";
import { LandingPageOnlineVoteSection } from "@components/LandingPage/LandingPageOnlineVoteSection";
import { LandingPageOurQuoteSection } from "@components/LandingPage/LandingPageOurQuoteSection";
import { LandingPageOverviewSection } from "@components/LandingPage/LandingPageOverviewSection";
import { LandingPageRegisterSection } from "@components/LandingPage/LandingPageRegisterSection";
import { LandingPageSavingWorkSection } from "@components/LandingPage/LandingPageSavingWorkSection";
import { LandingPageShareExperienceSection } from "@components/LandingPage/LandingPageShareExperienceSection";
import { PATRONS_DATA } from "@config/landingPageData";
import { AppInstance } from "@shared/utils/app-instance";
import { useTranslation } from "@translations/use-translation";
import { LandingPageContainer } from "@ui/Container";
import { LandingScreen } from "@ui/Layout/LandingScreen/LandingScreen";
import { Patrons } from "@ui/Patrons/Patrons";
import { Tab, TabPanel, Tabs } from "@ui/Tabs";
import { Span, TextH2 } from "@ui/Text";
import { Hide } from "@uxf/core/components/hide";
import { spacing } from "@uxf/styles/units/spacing";
import { NextPage } from "next";
import { useState } from "react";

const Homepage: NextPage = () => {
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    const tabsIdPrefix = "homepage-tabs";
    const { t } = useTranslation();

    return (
        <LandingScreen
            fixedNavBar={true}
            ogData={{
                ogTitle: t("page-index:og.title"),
                description: t("page-index:og.description"),
                image: "/static/images/landingPage/man-with-laptop.jpg",
            }}
            title={t("page-index:title")}
            preloadImgUrl="/static/images/landingPage/man-with-laptop.jpg"
        >
            <LandingPageHeroSection />
            <LandingPageOurQuoteSection />
            <LandingPageContainer mb={[spacing(5), null, spacing(6), spacing(8)]}>
                <TextH2
                    as="h2"
                    mb={[spacing(5), null, spacing(10)]}
                    textAlign="center"
                    textVariant={["h3", "h2", "h1"]}
                >
                    {t("page-index:onePlatformForAll")}
                </TextH2>
                <Tabs
                    $borderWidth={4}
                    activeTabIndex={activeTabIndex}
                    boxHeight={[48, null, 60]}
                    $inheritSelectedColor
                    onTabSelect={setActiveTabIndex}
                    tabsContentProps={{ justifyContent: ["flex-start", null, "space-evenly"] }}
                    tabsIdPrefix={tabsIdPrefix}
                >
                    <Tab
                        ml={[null, null, null, 40, 80]}
                        title={<Span textVariant={["h4", null, "h3"]}>{t("page-index:tabForOwners")}</Span>}
                        value={0}
                        boxWidth={["50%", "100%", "auto"]}
                        $colorVariant="primary"
                    />
                    <Tab
                        mr={[null, null, null, 40, 80]}
                        title={<Span textVariant={["h4", null, "h3"]}>{t("page-index:tabForAdministrators")}</Span>}
                        value={1}
                        boxWidth={["50%", "100%", "auto"]}
                        $colorVariant="brown"
                    />
                </Tabs>
            </LandingPageContainer>
            <TabPanel activeIndex={activeTabIndex} tabsIdPrefix={tabsIdPrefix} value={0}>
                <LandingPageDutiesControlSection />
                <LandingPageOnlineVoteSection />
                <LandingPageOverviewSection />
                <LandingPageRegisterSection />
                <LandingPageSavingWorkSection />
                <Hide when={AppInstance.isSlovak}>
                    <LandingPageShareExperienceSection />
                </Hide>
                <LandingPageMockUpsSection />
            </TabPanel>
            <TabPanel activeIndex={activeTabIndex} tabsIdPrefix={tabsIdPrefix} value={1}>
                <ServiceFeatureTab />
                <ServiceBenefitTab />
                <Hide when={AppInstance.isSlovak}>
                    <Patrons
                        data={[PATRONS_DATA.vlach, PATRONS_DATA.hajek, PATRONS_DATA.svamberk]}
                        title={t("page-index:patronsTitle")}
                        variant="brown"
                    />
                </Hide>
                <ServiceRegisterTab />
            </TabPanel>
        </LandingScreen>
    );
};

export default Homepage;
