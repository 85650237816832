import { BenefitGridItem } from "@components/LandingPage/HomePage/BenefitGridItem";
import { AppInstance } from "@shared/utils/app-instance";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { LandingPageContainer } from "@ui/Container";
import { Grid } from "@ui/Grid";
import { TextH3 } from "@ui/Text";
import { Hide } from "@uxf/core/components/hide";
import { spacing } from "@uxf/styles/units/spacing";
import { CSSProperties, FC, memo } from "react";
import { useTheme } from "styled-components";

const ServiceBenefitTabComponent: FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Box
            as="section"
            mt={spacing(4)}
            pt={spacing(9)}
            pb={spacing(10)}
            backgroundColor="brownLight"
            textColor="black"
            color="black"
        >
            <LandingPageContainer>
                <TextH3 as="h2" mb={[spacing(4), null, spacing(8)]}>
                    {t("component-landing-home-service-benefit-tab:what-neighbours-give")}
                </TextH3>
                <Grid
                    gridColumnCount={[1, 2, 3, 4]}
                    gridGap={[spacing(3), spacing(4), spacing(5), spacing(7)]}
                    style={{ "--benefits-accent": theme.color.palette.brown } as CSSProperties}
                >
                    <BenefitGridItem
                        icon="comment"
                        largeTitle
                        title={t("component-landing-home-service-benefit-tab:home-tab")}
                        text={t("component-landing-home-service-benefit-tab:communicate-effectively")}
                    />
                    <BenefitGridItem
                        icon="users"
                        largeTitle
                        title={t("component-landing-home-service-benefit-tab:contacts")}
                        text={t("component-landing-home-service-benefit-tab:register-contacts")}
                    />
                    <BenefitGridItem
                        icon="folder"
                        largeTitle
                        title={t("component-landing-home-service-benefit-tab:register-of-contracts")}
                        text={t("component-landing-home-service-benefit-tab:one-place-contracts")}
                    />
                    <BenefitGridItem
                        icon="search"
                        largeTitle
                        title={t("component-landing-home-service-benefit-tab:cadaster-control")}
                        text={t("component-landing-home-service-benefit-tab:abstract-of-changes")}
                    />
                    <BenefitGridItem
                        icon="shield"
                        largeTitle
                        title={t("component-landing-home-service-benefit-tab:guardian-angel")}
                        text={t("component-landing-home-service-benefit-tab:control-law-duties")}
                    />
                    <BenefitGridItem
                        icon="megaphone"
                        largeTitle
                        title={t("component-landing-home-service-benefit-tab:report-of-problem")}
                        text={t("component-landing-home-service-benefit-tab:connection-immediately-title")}
                    />
                    <Hide when={AppInstance.isSlovak}>
                        <BenefitGridItem
                            icon="hammer"
                            largeTitle
                            title={t("component-landing-home-service-benefit-tab:execution-and-insolvency")}
                            text={t("component-landing-home-service-benefit-tab:insolvency-and-exe-title")}
                        />
                    </Hide>
                    <BenefitGridItem
                        icon="poll"
                        largeTitle
                        title={t("component-landing-home-service-benefit-tab:online-voting")}
                        text={t("component-landing-home-service-benefit-tab:simple-voting-title")}
                    />
                </Grid>
            </LandingPageContainer>
        </Box>
    );
};

export const ServiceBenefitTab = memo(ServiceBenefitTabComponent);

ServiceBenefitTab.displayName = "ServiceBenefitTab";
