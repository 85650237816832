import { FlexBox } from "@ui/FlexBox";
import { TextLarge } from "@ui/Text";
import { spacing } from "@uxf/styles/units/spacing";
import { FC, useCallback } from "react";
import { TabClickable, TabTitle } from "./styles";
import { TabProps } from "./types";

export const Tab: FC<TabProps> = (props) => {
    const {
        $borderWidth,
        $colorVariant,
        boxHeight = 32,
        disabled,
        $inheritSelectedColor,
        isTabSelected,
        onTabSelect,
        tabsIdPrefix,
        title,
        value,
        ...restProps
    } = props;

    const onClickHandler = useCallback(() => {
        if (onTabSelect && value !== undefined) {
            onTabSelect(value);
        }
    }, [onTabSelect, value]);

    return (
        <FlexBox
            aria-controls={`${tabsIdPrefix}-tabpanel-${value}`}
            aria-selected={isTabSelected}
            boxWidth="auto"
            id={`${tabsIdPrefix}-tab-${value}`}
            justifyContent="center"
            role="tab"
            {...restProps}
        >
            <TabClickable
                $borderWidth={$borderWidth}
                $colorVariant={$colorVariant}
                $height={boxHeight}
                $inheritSelectedColor={$inheritSelectedColor}
                $isTabSelected={isTabSelected}
                disabled={disabled}
                onClick={onClickHandler}
            >
                <TextLarge
                    as="span"
                    pl={[spacing(3.5), null, null, null, spacing(5)]}
                    pr={[spacing(3.5), null, null, null, spacing(5)]}
                    textAlign="center"
                    textFontWeight="medium"
                >
                    <TabTitle className="tab-title">{title}</TabTitle>
                </TextLarge>
            </TabClickable>
        </FlexBox>
    );
};
