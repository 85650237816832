import { Layout } from "@ui/Layout/Layout";
import { FC, PropsWithChildren } from "react";
import { ILandingScreenProps } from "./types";

export const LandingScreen: FC<PropsWithChildren<ILandingScreenProps>> = (props) => {
    const { children, ...restProps } = props;

    return (
        <Layout backgroundColor="white" {...restProps}>
            {children}
        </Layout>
    );
};
