import { routeToUrl } from "@app-routes";
import { config } from "@config/config";
import { PRESENTATION_MEETING_URL } from "@config/landingPageData";
import { trackEvent } from "@services/analytics/trackEvent";
import { Trans } from "@translations/trans";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { Button } from "@ui/Button";
import { LandingPageContainer } from "@ui/Container";
import { FlexBox } from "@ui/FlexBox";
import { Image } from "@ui/Image";
import { Link } from "@ui/Link";
import { Text, TextH3, TextLarge } from "@ui/Text";
import { spacing } from "@uxf/styles/units/spacing";
import { FC, memo } from "react";

const LandingPageMockUpsSectionComponent: FC = () => {
    const { t } = useTranslation();
    return (
        <Box as="section" pb={spacing(5)} pt={[spacing(8), spacing(10), null, spacing(14)]}>
            <LandingPageContainer>
                <FlexBox justifyContent="center" alignItems="center" flexDirection={["column-reverse", null, "row"]}>
                    <Box mt={[spacing(6), spacing(8), 0]} mr={[null, null, spacing(4), spacing(8)]}>
                        <Text as="div" textAlign={["center", null, "left"]}>
                            <TextH3 textVariant={["h3", "h2", null, "h1"]} mb={spacing(5)}>
                                {t("component-landing-mock-up:life-is-easier")}
                            </TextH3>
                            <FlexBox flexDirection={["column", "row"]} maxWidth={[null, 462]} mb={spacing(3)}>
                                <FlexBox flexDirection="column" boxWidth={[null, "50%"]}>
                                    <Button
                                        analyticsCallback={trackEvent("LandingPage", "Registrace", "MockUps sekce")}
                                        href={routeToUrl("auth-zone/registration", {})}
                                        shadow
                                        size="giant"
                                        variant="secondary"
                                    >
                                        {t("component-landing-mock-up:try-for-free")}
                                    </Button>
                                </FlexBox>
                                <FlexBox
                                    flexDirection="column"
                                    boxWidth={[null, "50%"]}
                                    mt={[spacing(2), 0]}
                                    ml={[null, spacing(2)]}
                                >
                                    <Button
                                        analyticsCallback={trackEvent("LandingPage", "Ukázka", "MockUps sekce")}
                                        href={PRESENTATION_MEETING_URL}
                                        kind="outline"
                                        size="giant"
                                        target="_blank"
                                        variant="secondary"
                                    >
                                        {t("component-landing-mock-up:ask-for-show")}
                                    </Button>
                                </FlexBox>
                            </FlexBox>
                            <TextLarge>
                                <Trans
                                    i18nKey={"component-landing-mock-up:are-you-admin"}
                                    components={{
                                        link: (
                                            <Link
                                                href={`mailto:${config.TRANSLATION_BUSINESS_EMAIL}`}
                                                textColor="secondary"
                                                textFontWeight="medium"
                                                underline
                                            />
                                        ),
                                    }}
                                />
                            </TextLarge>
                        </Text>
                    </Box>
                    <Box>
                        <Image
                            className="illustration"
                            aspectRatio={515 / 345}
                            src="/static/images/mockups.png"
                            title={t("component-landing-mock-up:digital-support")}
                            imgProps={{ alt: t("component-landing-mock-up:digital-support") }}
                        />
                    </Box>
                </FlexBox>
            </LandingPageContainer>
        </Box>
    );
};

export const LandingPageMockUpsSection = memo(LandingPageMockUpsSectionComponent);

LandingPageMockUpsSection.displayName = "LandingPageMockUpsSection";
